<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <a-input-search
        class="mr-2"
         v-model:value="value"
           placeholder="Undifined"
           style="width: 300px"
           @search="onSearch"
           />
        <a-input-search
          v-model:value="value"
          placeholder="Hari"
          style="width: 300px"
          @search="onSearch"
          />

        <a-button class="ml-2" type= "primary" style="text-align:left;">Simpan</a-button>
      </div>
    </div>
    <div class="row">
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MFilter',
}
</script>

<style scoped>
.row {
  /* border: 1px dotted gray; */
}

</style>
